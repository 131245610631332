<template>
  <div class="tableList">
    <div class="table">
      <el-table  style="width: 100%">
          <el-table-column prop="" label="物料编号"></el-table-column>
          <el-table-column prop=""  label="物料描述"></el-table-column>
          <el-table-column prop=""  label="数量"></el-table-column>
          <el-table-column prop=""  label="出库基地"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChoiceDialog',

  data () {
    return {
    }
  },

  methods: {
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/tableList.scss';
.tableList{
padding: 0px;
}
</style>
